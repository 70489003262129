$(document).ready(function(){
	
	//table responsive
	$(".cardtype-price").each(function(){
	    $(this).wrapAll('<div class="table-responsive"></div>');
	});
	$(".cardtype-addons").each(function(){
	    $(this).wrapAll('<div class="table-responsive"></div>');
	});
    
    //mobile ad

	//Mobile NAV
	$(window).scroll(function(){
		if($(window).scrollTop() > 200 || $(window).width() < 768){
			$("#navigation").addClass("navbar-fixed-top");
		}else{
			$("#navigation").removeClass("navbar-fixed-top");
		}
	}).trigger('scroll');
    $('#bs-example-navbar-collapse-1').on('show.bs.collapse', function () {
    	$("#navbar-glyphicon").removeClass("glyphicon-menu-hamburger");
		$("#navbar-glyphicon").addClass("glyphicon-remove");
	});
	$('#bs-example-navbar-collapse-1').on('hide.bs.collapse', function () {
		$("#navbar-glyphicon").removeClass("glyphicon-remove");
		$("#navbar-glyphicon").addClass("glyphicon-menu-hamburger");
	});
	if($(window).width() <= 780){
		var img = $(".line").attr("src");
		img = img.replace(/header-img2/, "header-img3");
		$(".line").attr("src",img);

		if($(".schedule_wrapper")){
			var schedule_wrapper = $(".schedule_wrapper");
			var cal_wrapper = $(".cal_wrapper");
			$(".main").append(cal_wrapper);
			$(".main").append(schedule_wrapper);
		}
	}

	var imgWidth = 1055;
	var imgHeight= 315;
	var picw = $("[usemap='#line']").width() / imgWidth;
	var pich = $("[usemap='#line']").height() / imgHeight;

	$("#line").find("area").each(function(){
		var arr = new Array();
    	var coords = $(this).attr("coords");
    	var coords_val = "";
    	arr = coords.split(",");

    	coords_val = arr[0]*picw + "," + arr[1]*pich + "," + arr[2]*picw + ","+arr[3]*pich;

    	$(this).attr("coords",coords_val);
  	});

	var _src = $("[usemap='#line']").attr("src");
	$("#line > area:eq(1)").on({

	    mouseover: function(e){
	                $("[usemap='#line']").attr("src",_src.replace(/header-img2/, "header-img4"));
	            },

	    mouseout: function(e){ 
	   				$("[usemap='#line']").attr("src",_src);
	   			}
	});

	//验证表单
	$("#inputEmail").on("input propertychange",function(){
		var value = $(this).val();
		value = toSBC(value);
        value = value.replace(/[^\x00-\xff]/ig,"");
        $(this).val(value);
    });
    $("#inputZip").on("input propertychange",function(){
		var value = $(this).val();
		value = toSBC(value);
        value = value.replace(/[^0-9]/ig,"");
        if(value.length > 3) {
          $(this).val(value.substr(0,3) + "-" + value.substr(3,4));
        }else{
          $(this).val(value);
        }
    });
  

});//

function toSBC(str){
    var result = "";
    var len = str.length;
    for(var i=0;i<len;i++)
    {
        var cCode = str.charCodeAt(i);
        cCode = (cCode>=0xFF01 && cCode<=0xFF5E)?(cCode - 65248) : cCode;
        cCode = (cCode==0x03000)?0x0020:cCode;
        result += String.fromCharCode(cCode);
    }
    return result;
}
