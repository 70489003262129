/*
Main website JavaScript
*/
(function() {

'use strict';

// unsupported browser
if (!window.addEventListener || !window.history || !window.requestAnimationFrame || !document.getElementsByClassName) return;


// enable service worker
if ('serviceWorker' in navigator) {

  // register service worker
  navigator.serviceWorker.register('/serviceworker.js');

}


})();
